import React from "react";
import "./header.css";
import {useTranslation} from "react-i18next";
import intro from "../../img/base/intro.jpeg"

export const Header = (props) => {
    const {t} = useTranslation();

    const handleClick = () => {
        document.getElementById('cicloturisme').scrollIntoView({behavior: 'smooth'});
    };

    return (
        <header id="header">
            <img id={"introImg"} src={intro} alt="Ponent Explorers Home" title="Ponent Explorers"/>
            <div id={"introImgGradient"}></div>
            <div className="intro">
                <div className="overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 intro-text">
                                <h1>{t("client")}<span></span></h1>
                                <h2>{t("resum")}</h2>
                                <a className="button" style={{'--clr': '#12403C'}} onClick={handleClick}>
                                    {t("start")}
                                    <span className="button__icon-wrapper">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            className="button__icon-svg"
                                            stroke="currentColor"
                                            width="15"
                                            height="15"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                                            ></path>
                                        </svg>

                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            className="button__icon-svg button__icon-svg--copy"
                                            stroke="currentColor"
                                            width="15"
                                            height="15"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                                            ></path>
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}