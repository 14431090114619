import i18n from 'i18next';
import {initReactI18next} from "react-i18next";

i18n.use(initReactI18next).init({
    debug: false,
    fallbackLng: "ca",
    resources: {
        ca: {
            translation: {
                start: "Començar a Explorar",
                cicloturisme: "Cicloturisme",
                toursfets: "Tours Disponibles",
                amida: "Tours Privats",
                apeu: "A Peu",
                freetour: "Free Tours",
                privtour: "Visites Privades",
                empreses: "Empreses",
                contacte: {
                    contacte: "Contacta",
                    footer: {
                        contacta: "Contacta amb nosaltres",
                        xarxes: "Segueix-nos a les xarxes"
                    }
                },
                form: {
                    titol:"Explora amb nosaltres!",
                    subtitol:"Demana informació sobre la teva pròxima aventura",
                    name: "Nom",
                    namePlaceholder: "Entra el teu nom",
                    surname: "Cognoms",
                    surnamePlaceholder: "Entra els teus cognoms",
                    country: "País",
                    countryPlaceholder: "-- Selecciona el teu país --",
                    language: "Idioma del tour",
                    languagePlaceholder: "-- Selecciona un idioma --",
                    people: "Núm. de persones",
                    languageCA: "Català",
                    languageES: "Español",
                    languageEN: "English",
                    languageFR: "Français",
                    mail: "Correu",
                    mailPlaceholder: "Entra el teu email. Ex: correu@exemple.com",
                    phone: "Telèfon",
                    phonePlaceholder: "Entra el teu telèfon",
                    date0: "Dia",
                    date1: "Inici",
                    date2: "Final",
                    subject: "Assumpte",
                    subjectPlaceholder: "Entra un assumpte",
                    message: "Missatge",
                    messagePlaceholder: "Entra un missatge",
                    submit: "Enviar",
                    OK:"S'ha enviat un correu a PONENT EXPLORERS. En breu ens posarem en contacte amb tu!",
                    ERR:"No s'ha pogut enviar el formulari de contacte."
                },
                about: {
                    about: "Qui Som?",
                    about2: "Coneix qui estarà al teu costat",
                    projecte: "Aquest projecte sorgeix amb l'objectiu de donar a conèixer l'oest de Catalunya mitjançant la història, la gastronomia i el cicloturisme. Amb més de quatre anys d'experiència a França i la Costa Brava, es posa en marxa aquesta iniciativa centrada en la regió coneguda com \"LA TERRA FERMA\"",
                    marc: "Marc Sisó Vilagrasa",
                    feina: "Guia turístic i Fundador",
                    imatgeMarcAlt: "Imatge del guia turístic i fundador, Marc",
                    imatgeMarcTitle: "Guia turístic i fundador de Ponent Explorers",
                    marcCita: "Vaig estudiar història, però de seguida vaig veure que el que m’agradava era explicar-la sobre el terreny i el contacte amb la gent. L'oest de Catalunya és la terra on m'he criat i el meu cor em diu que formi part del seu creixement.",
                    marcTxt: "Després d’haver treballat dos anys a França com a guia, va traslladar-se a Girona on va estar treballant com a guia de cicloturisme per diverses agències internacionals mentre es formava en turisme i enoturisme a l’Escola de Turisme de Girona.\nMarc torna de nou a Lleida i engega aquest projecte per ensenyar i promocionar Ponent a través del turisme."
                },
                client: "PONENT EXPLORERS",
                resum: "Turisme cultural - Cicloturisme",
                trenLlacs: {
                    eslogan1: "Cap de setmana de tren, bici i natura!",
                    eslogan2: "FES LA RUTA DELS LLACS!",
                    contingut: "Comença a Lleida o Balaguer, pedala fins a St. Llorenç i agafa el tren de La Pobla a través de llacs i paisatges espectaculars. El segon dia, gaudeix d’un recorregut fàcil pel carril bici entre la Pobla de Segur i Cellers. Ideal per a tots els amants del cicloturisme, des dels principiants fins als més experimentats.",
                    esloganBoto: "Reserva'l ja!",
                    imatgesAlt: "Imatge de la ruta dels llacs",
                    form: {
                        subject: "RUTA DELS LLACS",
                        message: "Hola, Ponent Explorers!\n\nVoldria rebre informació sobre la Ruta dels Llacs."
                    }
                },
                montsec: {
                    eslogan1: "4 dies d'aventura, natura i història!",
                    eslogan2: "GRAN TOUR DEL MONTSEC",
                    contingut: "Coneix un dels paisatges més espectaculars de Catalunya, amb muntanyes escarpades, congostos profunds, valls verdes i vistes panoràmiques. Durant 4 etapes, els ciclistes exploren carreteres secundàries amb poc trànsit i camins rurals que travessen el cor de la serralada del Montsec. La ruta inclou pujades exigents, descensos emocionants i trams més plans, adaptant-se a diferents nivells de condició física.",
                    esloganBoto: "Reserva'l ja!",
                    imatgesAlt: "Imatge del gran tour del Montsec",
                    form: {
                        subject: "GRAN TOUR DEL MONTSEC",
                        message: "Hola, Ponent Explorers!\n\nVoldria rebre informació sobre el Gran Tour del Montsec."
                    }
                },
                vallfosca: {
                    eslogan1: "Visita el Pirineu català",
                    eslogan2: "VALL FOSCA EN BTT!",
                    contingut: "Anar amb bicicleta per la Vall Fosca, al Pallars Jussà, és una experiència única pels seus paisatges impressionants, estanys glacials i una atmosfera tranquil·la lluny del turisme massiu. La ruta combina carreteres poc transitades i camins rurals amb pujades i vistes panoràmiques. A més, permet descobrir pobles amb gastronomia local, així com la història de la vall, marcada per l’energia hidroelèctrica i la vida rural.",
                    esloganBoto: "Reserva'l ja!",
                    imatgesAlt: "Imatge de la ruta per la Vall Fosca",
                    form: {
                        subject: "VALL FOSCA EN BTT",
                        message: "Hola, Ponent Explorers!\n\nVoldria rebre informació sobre la ruta en BTT per la Vall Fosca."
                    }
                },
                biciprivat: {
                    eslogan1: "Vols organitzar un viatge o una ruta amb amics?",
                    eslogan2: "TOURS PRIVATS EN BICI",
                    contingut: "Nosaltres ens encarreguem de tot. De la reserva de l'allotjament, del disseny dels tracks, del transport de les bicicletes, les maletes i tot el material necessari.\n" +
                        "Treballem allà on tu ens demanis. Contacta'ns i t'enviarem una proposta detallada.",
                    esloganBoto: "Contactar",
                    imatgesAlt: "Imatge de tours privats en bicicleta",
                    form: {
                        subject: "TOUR PRIVAT EN BICI",
                        message: "Hola, Ponent Explorers!\n\nVoldria contractar un tour privat en bici.\n\nQuè ens proposeu?"
                    }
                },
                lleida: {
                    eslogan1: "Història, cultura i llegendes",
                    eslogan2: "FREE TOUR LLEIDA!",
                    contingut: "No hi ha millor manera de conèixer la ciutat que amb un guia oficial local! M'acompanyes a conèixer Lleida?\n" +
                        "Començarem a Rambla Ferran, veient una Lleida moderna per a després endinsar-nos a la Plaça de Sant Joan presidida per l'església homònima.\n" +
                        "Seguirem fins a conèixer Indíbil i Mandoni, símbol de la resistència Ilergeta. A través de la plaça de la Paeria, coneixerem la història medieval de la ciutat.\n" +
                        "A poc a poc pujarem turó amunt, a través del barri antic, visitant el Convent del Roser, la Seu Nova, el IEI, l'Església de Sant Llorenç i finalment acabarem en el monument preferit dels catalans, triat en el 2022, La Seu Vella, amb la seva apassionant història i unes vistes sobre tota la plana realment impressionants!",
                    esloganBoto: "Reserva'l ja!",
                    imatgesAlt: "Imatge de free tours",
                    form: {
                        subject: "FREE TOUR PER LLEIDA",
                        message: "Hola, Ponent Explorers!\n\nVoldria contractar un Free Tour per Lleida amb vosaltres.\n\nEsperem confirmació."
                    }
                },
                peuprivat: {
                    eslogan1: "Vols organitzar una excursió?",
                    eslogan2: "VISITES PRIVADES PER PONENT",
                    contingut: "Planifica la teva sortida amb nosaltres.\n" +
                        "Som experts en el nostre destí i volem que visquis una experiència única i inoblidable. Dissenyem els millors tours i experiències perquè gaudeixis i coneguis la cultura i la història de les nostres terres.\n" +
                        "Reserva tours i visites guiades al centre històric dels pobles i ciutats de Ponent i als monuments més visitats.",
                    esloganBoto: "Reserva'l ja!",
                    imatgesAlt: "Imatge de visites guiades privades",
                    form: {
                        subject: "VISITA PRIVADA PER PONENT",
                        message: "Hola, Ponent Explorers!\n\nVoldria contractar una visita guiada privada per Ponent.\n\nQuè ens proposeu?"
                    }
                },
            }
        },
        es: {
            translation: {
                start: "Empezar a Explorar",
                cicloturisme: "Cicloturismo",
                toursfets: "Tours Disponibles",
                amida: "Tours Privados",
                apeu: "A Pie",
                freetour: "Free Tours",
                privtour: "Visitas Privadas",
                empreses: "Empresas",
                contacte: {
                    contacte: "Contacta",
                    footer: {
                        contacta: "Contacta con nosotros",
                        xarxes: "Síguenos en redes"
                    }
                },
                form: {
                    titol:"Explora con nosotros!",
                    subtitol:"Pide información sobre tu próxima aventura",
                    name: "Nombre",
                    namePlaceholder: "Entra tu nombre",
                    surname: "Apellidos",
                    surnamePlaceholder: "Entra tus apellidos",
                    country: "País",
                    countryPlaceholder: "-- Selecciona tu país --",
                    language: "Idioma del tour",
                    languagePlaceholder: "-- Selecciona un idioma --",
                    people: "N.º de personas",
                    languageCA: "Català",
                    languageES: "Español",
                    languageEN: "English",
                    languageFR: "Français",
                    mail: "Correo",
                    mailPlaceholder: "Entra tu email. Ex: correo@ejemplo.com",
                    phone: "Teléfono",
                    phonePlaceholder: "Entra tu teléfono",
                    date0: "Día",
                    date1: "Inicio",
                    date2: "Final",
                    subject: "Asunto",
                    subjectPlaceholder: "Entra un asunto",
                    message: "Mensaje",
                    messagePlaceholder: "Entra un mensaje",
                    submit: "Enviar",
                    OK:"Se ha enviado un correo a PONIENDO EXPLORERS. ¡En breve nos pondremos en contacto contigo!",
                    ERR:"No se ha podido enviar el formulario de contacto."
                },
                about: {
                    about: "¿Quiénes Somos?",
                    about2: "Conoce quién estará a tu lado",
                    projecte: "Este proyecto surge con el objetivo de dar a conocer el oeste de Cataluña mediante la historia, la gastronomía y el cicloturismo. Con más de cuatro años de experiencia en Francia y la Costa Brava, se pone en marcha esta iniciativa centrada en la región conocida como \"LA TERRA FERMA\"",
                    marc: "Marc Sisó Vilagrasa",
                    imatgeMarcAlt: "Imagen del guía turístico y fundador, Marc",
                    imatgeMarcTitle: "Guía turístico y fundador de Ponent Explorers",
                    feina: "Guía turístico y Fundador",
                    marcCita: "Estudié historia, pero enseguida vi que lo que me gustaba era explicarla sobre el terreno y el contacto con la gente. El oeste de Cataluña es la tierra donde me he criado y mi corazón me dice que forme parte de su crecimiento.",
                    marcTxt: "Después de haber trabajado dos años en Francia como guía, se trasladó a Girona donde estuvo trabajando como guía de cicloturismo por varias agencias internacionales mientras se formaba en turismo y enoturisme en la Escuela de Turismo de Girona.\nMarc vuelve de nuevo a Lleida y pone en marcha este proyecto para enseñar y promocionar \"Ponent\" a través del turismo."
                },
                client: "PONENT EXPLORERS",
                resum: "Turismo cultural - Cicloturismo",
                trenLlacs: {
                    eslogan1: "¡Fin de semana de tren, bici y natura!",
                    eslogan2: "¡HAZ LA RUTA DE LOS LAGOS!",
                    contingut: "Empieza en Lleida o Balaguer, pedalea hasta St. Llorenç y coge el tren de La Pobla a través de lagos y paisajes espectaculares. El segundo día, disfruta de un recorrido fácil por el carril bici entre la Pobla de Segur y Cellers. Ideal para todos los amantes del cicloturismo, desde los principiantes hasta los más experimentados.",
                    esloganBoto: "Resérvalo ya!",
                    imatgesAlt: "Imagen de la ruta de los lagos",
                    form: {
                        subject: "RUTA DE LOS LAGOS",
                        message: "Hola, Ponent Explorers!\n\nQuisiera recibir información sobre la Ruta de los Lagos."
                    }
                },
                montsec: {
                    eslogan1: "4 días de aventura, natura e historia!",
                    eslogan2: "GRAN TOUR DEL MONTSEC",
                    contingut: "Conoce uno de los paisajes más espectaculares de Cataluña, con montañas escarpadas, desfiladeros profundos, valles verdes y vistas panorámicas. Durante 4 etapas, los ciclistas exploran carreteras secundarias con poco tráfico y caminos rurales que atraviesan el corazón de la cordillera del Montsec. La ruta incluye subidas exigentes, descensos emocionantes y tramos más planos, adaptándose a diferentes niveles de condición física.",
                    esloganBoto: "Resérvalo ya!",
                    imatgesAlt: "Imagen del gran tour del Monstsec",
                    form: {
                        subject: "GRAN TOUR DEL MONTSEC",
                        message: "Hola, Ponent Explorers!\n\nQuisiera recibir información sobre el Gran Tour del Montsec."
                    }
                },
                vallfosca: {
                    eslogan1: "Visita el Pirineo catalán",
                    eslogan2: "VALL FOSCA EN BTT!",
                    contingut: "Ir en bicicleta por la Vall Fosca, en el Pallars Jussà, es una experiencia única por sus paisajes impresionantes, estaños glaciales y una atmósfera tranquila lejos del turismo masivo. La ruta combina carreteras poco transitadas y caminos rurales con subidas y vistas panorámicas. Además, permite descubrir pueblos con gastronomía local, así como la historia del valle, marcada por la energía hidroeléctrica y la vida rural.",
                    esloganBoto: "Resérvalo ya!",
                    imatgesAlt: "Imagen de la ruta por la Vall Fosca",
                    form: {
                        subject: "VALL FOSCA EN BTT",
                        message: "Hola, Ponent Explorers!\n\nQuisiera recibir información sobre la ruta en BTT por la Vall Fosca."
                    }
                },
                biciprivat: {
                    eslogan1: "Quieres organizar un viaje o una ruta con amigos?",
                    eslogan2: "TOURS PRIVADOS EN BICI",
                    contingut: "Nosotros nos encargamos de todo. De la reserva del alojamiento, del diseño de los tracks, del transporte de las bicicletas, las maletas y todo el material necesario.\n" +
                        "Trabajamos allá donde tú nos pidas. Contáctanos y te enviaremos una propuesta detallada.",
                    esloganBoto: "Contactar",
                    imatgesAlt: "Imagen de tours privados en bicicleta",
                    form: {
                        subject: "TOUR PRIVADO EN BICI",
                        message: "Hola, Ponent Explorers!\n\nQuisiera contratar un tour privado en bici.\n\n¿Qué nos proponéis?"
                    }
                },
                lleida: {
                    eslogan1: "Historia, cultura y leyendas",
                    eslogan2: "FREE TOUR LLEIDA!",
                    contingut: "No hay mejor manera de conocer la ciudad que con un guía oficial local! Me acompañas a conocer Lleida?\n" +
                        "Empezaremos en Rambla Ferran, viendo una Lleida moderna para después adentrarnos en la Plaza de Sant Joan presidida por la iglesia homónima.\n" +
                        "Seguiremos hasta conocer Indíbil y Mandoni, símbolo de la resistencia Ilergeta. A través de la plaza de la Paeria, conoceremos la historia medieval de la ciudad.\n" +
                        "Poco a poco subiremos colina arriba, a través del barrio antiguo, visitando el Convento del Roser, la Seu Nova, el IEI, la Iglesia de Sant Llorenç y finalmente acabaremos en el monumento preferido de los catalanes, elegido en el 2022, La Seu Vella, con su apasionante historia y unas vistas sobre toda la llanura realmente impresionantes!",
                    esloganBoto: "Resérvalo ya!",
                    imatgesAlt: "Imagen de free tours",
                    form: {
                        subject: "FREE TOUR POR LLEIDA",
                        message: "Hola, Ponent Explorers!\n\nQuisiera contratar un Free Tour por Lleida con vosotros.\n\nEsperamos confirmación."
                    }
                },
                peuprivat: {
                    eslogan1: "¿Quieres organizar una excursión?",
                    eslogan2: "VISITAS PRIVADAS POR \"PONENT\"",
                    contingut: "Planifica tu salida con nosotros.\n" +
                        "Somos expertos en nuestro destino y queremos que vivas una experiencia única e inolvidable. Diseñamos los mejores tours y experiencias para que disfrutes y conozcas la cultura y la historia de nuestras tierras.\n" +
                        "Reserva tours y visitas guiadas en el centro histórico de los pueblos y ciudades de \"Ponent\" y a los monumentos más visitados.",
                    esloganBoto: "Resérvalo ya!",
                    imatgesAlt: "Imagen de visitas guiadas privadas",
                    form: {
                        subject: "VISITA PRIVADA POR PONENT",
                        message: "Hola, Ponent Explorers!\n\nQuisiera contratar una visita guiada privada por Ponent.\n\n¿Qué nos proponéis?"
                    }
                },
            }
        },
        en: {
            translation: {
                start: "Start Exploring",
                cicloturisme: "Cycle Tourism",
                toursfets: "Available Tours",
                amida: "Private Tours",
                apeu: "On Foot",
                freetour: "Free Tours",
                privtour: "Private Tours",
                empreses: "Companies",
                contacte: {
                    contacte: "Contact",
                    footer: {
                        contacta: "Contact us",
                        xarxes: "Follow us on social"
                    }
                },
                form: {
                    titol:"Explore with us!",
                    subtitol:"Ask for information about your next adventure",
                    name: "First name",
                    namePlaceholder: "Enter your first name",
                    surname: "Last name",
                    surnamePlaceholder: "Enter your last name",
                    country: "Country",
                    countryPlaceholder: "-- Select your country --",
                    language: "Tour's language",
                    languagePlaceholder: "-- Select a language --",
                    people: "Number of people",
                    languageCA: "Català",
                    languageES: "Español",
                    languageEN: "English",
                    languageFR: "Français",
                    mail: "Email",
                    mailPlaceholder: "Enter your email. Ex: email@example.com",
                    phone: "Phone",
                    phonePlaceholder: "Enter your phone",
                    date0: "Day",
                    date1: "Start",
                    date2: "End",
                    subject: "Subject",
                    subjectPlaceholder: "Enter a subject",
                    message: "Message",
                    messagePlaceholder: "Enter a message",
                    submit: "Submit",
                    OK: "An email has been sent to PONENT EXPLORERS. We will contact you shortly!",
                    ERR: "The contact form could not be sent."
                },
                about: {
                    about: "About Us",
                    about2: "Know who will be by your side",
                    projecte: "This project was created with the aim of promoting the west of Catalonia through history, gastronomy and cycle tourism. With more than four years of experience in France and Costa Brava, this initiative is launched focusing on the region known as \"LA TERRA FERMA\"",
                    marc: "Marc Sisó Vilagrasa",
                    imatgeMarcAlt: "Image of tour guide and founder, Marc",
                    imatgeMarcTitle: "Tour guide and founder of Ponent Explorers",
                    feina: "Tour Guide and Founder",
                    marcCita: "I studied history, but I soon realised that what I liked was explaining it on the ground and getting in touch with people. The west of Catalonia is the land where I grew up and my heart tells me to be part of its growth.",
                    marcTxt: "After working for two years in France as a guide, he moved to Girona where he worked as a cycle tourism guide for various international agencies while training in tourism and wine tourism at the Girona School of Tourism.\nMarc returns to Lleida and launches this project to teach and promote \"Ponent\" through tourism."
                },
                client: "PONENT EXPLORERS",
                resum: "Cultural Tourism - Cycle tourism",
                trenLlacs: {
                    eslogan1: "A weekend of train, bike and nature!",
                    eslogan2: "TAKE THE LAKES ROUTE!",
                    contingut: "Start in Lleida or Balaguer, cycle to St. Llorenç and take the train to La Pobla through lakes and spectacular landscapes. On the second day, enjoy an easy ride along the cycle path between La Pobla de Segur and Cellers. Ideal for all lovers of cycle tourism, from beginners to the most experienced.",
                    esloganBoto: "Book now!",
                    imatgesAlt: "Image of the lakes route",
                    form: {
                        subject: "LAKES ROUTE",
                        message: "Hello, Ponent Explorers!\n\nI would like to receive information about the Lakes Route."
                    }
                },
                montsec: {
                    eslogan1: "4 days of adventure, nature and history!",
                    eslogan2: "MONTSEC'S GREAT TOUR",
                    contingut: "Discover one of the most spectacular landscapes in Catalonia, with rugged mountains, deep gorges, green valleys and panoramic views. Over 4 stages, cyclists explore low-traffic back roads and country lanes through the heart of the Montsec mountain range. The route includes challenging climbs, thrilling descents and flatter sections, catering to different fitness levels.",
                    esloganBoto: "Book now!",
                    imatgesAlt: "Image of the Montsec's great tour",
                    form: {
                        subject: "GREAT TOUR OF MONTSEC",
                        message: "Hello, Ponent Explorers!\n\nI would like to receive information about the Great Tour of Montsec."
                    }
                },
                vallfosca: {
                    eslogan1: "Visit the Catalan Pyrenees",
                    eslogan2: "VALL FOSCA BY MOUNTAIN BIKE!",
                    contingut: "Cycling through Vall Fosca, in Pallars Jussà, is a unique experience due to its impressive landscapes, glacial ice caps and a peaceful atmosphere far from mass tourism. The route combines little-used roads and rural paths with climbs and panoramic views. It also allows you to discover villages with local cuisine, as well as the history of the valley, marked by hydroelectric power and rural life.",
                    esloganBoto: "Book now!",
                    imatgesAlt: "Image of the route through Vall Fosca",
                    form: {
                        subject: "VALL FOSCA BY MOUNTAIN BIKE",
                        message: "Hello, Ponent Explorers!\n\nI would like to receive information about the MTB route through Vall Fosca."
                    }
                },
                biciprivat: {
                    eslogan1: "Do you want to organize a trip or a route with friends?",
                    eslogan2: "PRIVATE BIKE TOURS",
                    contingut: "We take care of everything. The accommodation reservation, the track design, the transport of the bicycles, the suitcases and all the necessary material.\n" +
                        "We work wherever you ask us. Contact us and we will send you a detailed proposal.",
                    esloganBoto: "Contact",
                    imatgesAlt: "Image of private bike tours",
                    form: {
                        subject: "PRIVATE BIKE TOUR",
                        message: "Hello, Ponent Explorers!\n\nI would like to book a private bike tour.\n\nWhat do you suggest?"
                    }
                },
                lleida: {
                    eslogan1: "History, culture and legends",
                    eslogan2: "FREE TOUR LLEIDA!",
                    contingut: "There is no better way to get to know the city than with an official local guide! Will you join me to get to know Lleida?\n" +
                        "We will start at Rambla Ferran, seeing a modern Lleida and then enter the Sant Joan square presided over by the church of the same name.\n" +
                        "We will continue until we see Indíbil i Mandoni, a symbol of the Ilergeta resistance. Through the Paeria square, we will learn about the medieval history of the city.\n" +
                        "Little by little we will go up the hill, through the old quarter, visiting the Convent del Roser, the Seu Nova, the IEI, the Church of Sant Llorenç and finally we will end up at the favorite monument of the catalans, chosen in 2022, La Seu Vella, with its fascinating history and awesome views over the entire plain!",
                    esloganBoto: "Book now!",
                    imatgesAlt: "Image of free tours",
                    form: {
                        subject: "FREE TOUR IN LLEIDA",
                        message: "Hello, Ponent Explorers!\n\nI would like to book a Free Tour in Lleida with you.\n\nWe await confirmation."
                    }
                },
                peuprivat: {
                    eslogan1: "Do you want to organize an excursion?",
                    eslogan2: "PRIVATE TOURS IN \"PONENT\"",
                    contingut: "Plan your trip with us.\n" +
                        "We are experts in our destination and we want you to live a unique and unforgettable experience. We design the best tours and experiences so that you can enjoy and learn about the culture and history of our lands.\n" +
                        "Book tours and guided visits in the historic center of the towns and cities of \"Ponent\" and to the most visited monuments.",
                    esloganBoto: "Book now!",
                    imatgesAlt: "Image of private guided tours",
                    form: {
                        subject: "PRIVATE TOUR IN PONENT",
                        message: "Hello, Ponent Explorers!\n\nI would like to book a private guided tour in Ponent.\n\nWhat do you suggest?"
                    }

                },
            }
        }
    }
});

export default i18n;