import React from "react";
import {useTranslation} from "react-i18next";
import {SectionSubtitle} from "../../section-title/section-subtitle";
import {Card} from "../../card/card";
import "./amida.css";


import guiatge from "../../../img/cicloturisme/amida/guiatge.jpg"
import remolc from "../../../img/cicloturisme/amida/remolc.jpg"
import ruta1 from "../../../img/cicloturisme/amida/ruta1.jpg"
import ruta2 from "../../../img/cicloturisme/amida/ruta2.jpg"
import ruta3 from "../../../img/cicloturisme/amida/ruta3.jpg"
import ruta4 from "../../../img/cicloturisme/amida/ruta4.jpg"
import ruta5 from "../../../img/cicloturisme/amida/ruta5.jpg"


export const Amida = (props) => {
    const {t} = useTranslation();

    return (
        <div id="cicloturisme-privats">
            <SectionSubtitle title={t("amida")}/>
            <Card id={"carouselImatgesAmida"} href={"#contacta"}
                  imatges={[guiatge, remolc, ruta1, ruta2, ruta3, ruta4, ruta5]}
                  imatgesAlt={t("biciprivat.imatgesAlt")}
                  textEslogan={t("biciprivat.eslogan1")}
                  textTitolPrincipal={t("biciprivat.eslogan2")} textContingut={t("biciprivat.contingut")}
                  textBoto={t("biciprivat.esloganBoto")}
                  subject={t("biciprivat.form.subject")}
                  message={t("biciprivat.form.message")}
                  showOneData={false}
                  showTwoData={true}
                  showLanguage={false}
                  key={`${props.customkey}_privat`}
            />
        </div>
    )
}