import React from "react";
import {Card} from "../../card/card";
import "./privades.css";
import {useTranslation} from "react-i18next";
import {SectionSubtitle} from "../../section-title/section-subtitle";

import grup from "../../../img/apeu/privats/grup.jpg"
import grup1 from "../../../img/apeu/privats/grup1.jpg"
import grup2 from "../../../img/apeu/privats/grup2.jpg"
import grup3 from "../../../img/apeu/privats/grup3.png"


export const Privades = (props) => {
    const {t} = useTranslation();

    return (
        <div id="a-peu-privades">
            <SectionSubtitle title={t("privtour")}/>
            <Card id={"carouselImatgesTourPrivatAPeu"}
                  href={"#contacta"}
                  imatges={[grup, grup1, grup2, grup3]}
                  imatgesAlt={t("peuprivat.imatgesAlt")}
                  textEslogan={t("peuprivat.eslogan1")}
                  textTitolPrincipal={t("peuprivat.eslogan2")} textContingut={t("peuprivat.contingut")}
                  textBoto={t("peuprivat.esloganBoto")}
                  subject={t("peuprivat.form.subject")}
                  message={t("peuprivat.form.message")}
                  showOneData={true}
                  showTwoData={false}
                  showLanguage={true}
                  customkey={`${props.customkey}_privtour`}
            />
        </div>
    )
}