import React from 'react';
import "./footer.css";
import {useTranslation} from "react-i18next";
// import Guru from "../../img/contact/guru.svg";
import {Form} from "../form/form";

export const Footer = (props) => {
    const {t} = useTranslation();

    return (
        <div id="contacta">
            <Form/>
            <div className={"container"}>
                <div className="social">
                    <div>
                        <h4>{t("contacte.footer.contacta")}</h4>
                        <ul>
                            <li>
                                <a href={"tel:+34639533606"}>
                                    <i className="fa fa-phone"></i>
                                </a>
                            </li>
                            <li>
                                <a href={"https://wa.me/639533606"}>
                                    <i className="fa fa-whatsapp"></i>
                                </a>
                            </li>
                            <li>
                                <a href={"mailto:info@ponentexplorers.com"}>
                                    <i className="fa fa-envelope-o"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h4>{t("contacte.footer.xarxes")}</h4>
                        <ul>
                            <li>
                                <a href={"https://www.instagram.com/marc.guide"}>
                                    <i className="fa fa-instagram"></i>
                                </a>
                            </li>
                        </ul>
                        {/*<li>*/}
                        {/*    <a href={"https://www.guruwalk.com/es/walks/57529-free-tour-lleida-historia-cultura-y-leyendas"}>*/}
                        {/*        <i className="fa">*/}
                        {/*            <Guru className="custom-svg"/>*/}
                        {/*        </i>*/}
                        {/*    </a>*/}
                        {/*</li>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}