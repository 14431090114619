import './App.css';
import './i18n';
import {useTranslation} from 'react-i18next';
import React, {useEffect} from "react";
import {Navbar} from "./components/navbar/navbar";
import {Header} from "./components/header/header";
import {Cicloturisme} from "./components/cicloturisme/cicloturisme";
import {Apeu} from "./components/apeu/apeu";
import {Portfolio} from "./components/portfolio/portfolio";
import {Footer} from "./components/footer/footer";
import {FormProvider} from "./context";

function App() {
    const {i18n} = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(navigator.language);
    }, [i18n]);

    return (
        <div className="App">
            <Navbar/>
            <Header/>
            <FormProvider>
                <Cicloturisme/>
                <Apeu/>
                <Portfolio/>
                <Footer/>
            </FormProvider>
        </div>
    );
}

export default App;
