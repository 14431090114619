import React from 'react';
import "./form.css";
import {useTranslation} from "react-i18next";
import {useState, useContext} from "react";
import {FormContext} from "../../context";
import languages from "./languages";
import countries from "./countries";

const emptyForm = {
    name: "",
    surname: "",
    country: "",
    language: "",
    email: "",
    phone: "",
    people: 1,
    date0: "",
    date1: "",
    date2: "",
};

export const Form = (props) => {
    const {t} = useTranslation();

    const okMessageBox = () => {
        return (
            <div className="success">
                <div className="success__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" height="24" fill="none">
                        <path fillRule="evenodd" fill="#393a37"
                              d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z"
                              clipRule="evenodd"></path>
                    </svg>
                </div>
                <div className="success__title">{t("form.OK")}</div>
                <div className="success__close" onClick={closeMessageBox}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" height="20">
                        <path fill="#393a37"
                              d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z"></path>
                    </svg>
                </div>
            </div>
        );
    }

    const errMessageBox = () => {
        return (
            <div className="error">
                <div className="error__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" height="24" fill="none">
                        <path fill="#393a37"
                              d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"></path>
                    </svg>
                </div>
                <div className="error__title">{t("form.ERR")}</div>
                <div className="error__close" onClick={closeMessageBox}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" height="20">
                        <path fill="#393a37"
                              d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z"></path>
                    </svg>
                </div>
            </div>
        )
    }

    const [messageOK, setMessageOK] = useState(false);
    const [messageErr, setMessageErr] = useState(false);

    const {formData, setFormData} = useContext(FormContext);

    const [{
        name,
        surname,
        country,
        language,
        email,
        phone,
        people,
        date0,
        date1,
        date2
    }, setState] = useState(emptyForm);
    const clearForm = () => {
        setState({...emptyForm});
        setFormData({});
    }

    const changeInput = (e) => {
        const {id, value} = e.target;
        setState((prevState) => ({...prevState, [id]: value}));
    }

    const changeCustomInput = (e) => {
        const {id, value} = e.target;
        setFormData((prevState) => ({...prevState, [id]: value}));
    }

    const closeMessageBox = (e) => {
        setMessageErr(false);
        setMessageOK(false);
    }

    const submitForm = async (event) => {
        event.preventDefault();

        const inputData = {
            name,
            surname,
            email,
            phone,
            country,
            language,
            people,
            date0,
            date1,
            date2,
            subject: formData.subject,
            message: formData.message
        }

        try {
            const response = await fetch('/api/email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(inputData)
            });

            if (response.ok) {
                setMessageErr(false);
                setMessageOK(true);
                clearForm();
            } else {
                setMessageOK(false);
                setMessageErr(true);
            }
        } catch (error) {
            setMessageOK(false);
            setMessageErr(true);
        }
    }

    return (
        <div id="form">
            <div className={"container"}>
                <div className="col-md-12 section-title text-align-left">
                    <h3>{t("form.titol")}</h3>
                    <p>{t("form.subtitol")}</p>
                </div>
                <form id={"sendMessage"} name={"sendMessage"} onSubmit={submitForm}>
                    <div className={"row"}>
                        <div className={"col-md-3 form-group"}>
                            <label htmlFor={"name"}>{t("form.name")}</label>
                            <input type={"text"} id={"name"} name={"name"} value={name} onChange={changeInput}
                                   placeholder={t("form.namePlaceholder")} required/>
                        </div>
                        <div className={"col-md-3 form-group"}>
                            <label htmlFor={"surname"}>{t("form.surname")}</label>
                            <input type={"text"} id={"surname"} name={"surname"} value={surname} onChange={changeInput}
                                   placeholder={t("form.surnamePlaceholder")}/>
                        </div>
                        <div className={"col-md-4 form-group"}>
                            <label htmlFor={"email"}>{t("form.mail")}</label>
                            <input type={"email"} id={"email"} name={"email"} value={email} onChange={changeInput}
                                   placeholder={t("form.mailPlaceholder")} required/>
                        </div>
                        <div className={"col-md-2 form-group"}>
                            <label htmlFor={"phone"}>{t("form.phone")}</label>
                            <input type={"tel"} id={"phone"} name={"phone"} autoComplete={"tel"} value={phone}
                                   onChange={changeInput}
                                   placeholder={t("form.phonePlaceholder")}/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-4 form-group"}>
                            <label htmlFor={"country"}>{t("form.country")}</label>
                            <select id={"country"} name={"country"} value={country} form="sendMessage"
                                    onChange={changeInput}>
                                <option value={""}>{t("form.countryPlaceholder")}</option>
                                {countries.map((l, index) => {
                                    return (<option key={`form-country-option-${index}`} value={l.text.ca}>{navigator.language.includes("en") ? l.text.en : navigator.language.includes("es") ? l.text.es : l.text.ca}</option>)
                                })}
                            </select>
                        </div>
                        {
                            formData.showLanguage ?
                                (
                                    <div className={"col-md-4 form-group"}>
                                        <label htmlFor={"language"}>{t("form.language")}</label>
                                        <select id={"language"} name={"language"} value={language} form="sendMessage"
                                                onChange={changeInput} required>
                                            <option value={""}>{t("form.languagePlaceholder")}</option>
                                            {languages.map((l, index) => {
                                                return (<option key={`form-language-option-${index}`} value={l.value}>{t(l.textRef)}</option>)
                                            })}
                                        </select>
                                    </div>
                                ) : null}
                    </div>
                    <div className={"row"}>
                        {
                            formData.showOneData ?
                                (
                                    <div className={"col-md-3 form-group"}>
                                        <label htmlFor={"date0"}>{t("form.date0")}</label>
                                        <input type={"date"} id={"date0"} name={"date0"} value={date0}
                                               min={new Date().toISOString().split("T")[0]} onChange={changeInput}
                                               required/>
                                    </div>
                                )
                                : formData.showTwoData ?
                                    (
                                        <div>
                                            <div className={"col-md-3 form-group"}>
                                                <label htmlFor={"date1"}>{t("form.date1")}</label>
                                                <input type={"date"} id={"date1"} name={"date1"} value={date1}
                                                       min={new Date().toISOString().split("T")[0]}
                                                       onChange={changeInput}
                                                       required/>
                                            </div>
                                            <div className={"col-md-3 form-group"}>
                                                <label htmlFor={"date2"}>{t("form.date2")}</label>
                                                <input type={"date"} id={"date2"} name={"date2"} value={date2}
                                                       min={new Date().toISOString().split("T")[0]}
                                                       onChange={changeInput}
                                                       required/>
                                            </div>
                                        </div>
                                    )
                                    : null
                        }
                        {
                            formData.showPeople ?
                                (
                                    <div className={"col-md-2 form-group"}>
                                        <label htmlFor={"people"}>{t("form.people")}</label>
                                        <input type={"number"} id={"people"} name={"people"} value={people}
                                               onChange={changeInput} min={1} required/>
                                    </div>
                                ) : null
                        }
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-8 form-group"}>
                            <label htmlFor={"subject"}>{t("form.subject")}</label>
                            <input type="text" id={"subject"} name={"subject"}
                                // value={subject}
                                   onChange={changeCustomInput}
                                   value={formData.subject || ''}
                                   placeholder={t("form.subjectPlaceholder")} required/>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-12 form-group"}>
                            <label htmlFor={"message"}>{t("form.message")}</label>
                            <textarea id={"message"} name={"message"} rows={6}
                                // value={message}
                                      onChange={changeCustomInput}
                                      value={formData.message || ''}
                                      placeholder={t("form.messagePlaceholder")} required/>
                        </div>
                    </div>

                    <div className={"submitButton-div"}>
                        {
                            messageOK ?
                                (<div id={"resultMessageBoxOk"}>{okMessageBox()}</div>) :
                                messageErr ?
                                    (<div id={"resultMessageBoxErr"}>{errMessageBox()}</div>)
                                    :
                                    null}
                        <button className={"submitButton"} type="submit">{t("form.submit")}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}