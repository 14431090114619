import React from 'react';
import {useContext} from "react";
import {FormContext} from "../../context";
import "./card.css";

export const Card = (props) => {
    const {setFormData} = useContext(FormContext);

    const handleClick = () => {
        setFormData({
            subject: props.subject,
            message: props.message,
            showOneData: props.showOneData,
            showTwoData: props.showTwoData,
            showLanguage: props.showLanguage,
            showPeople: true,
        });
        document.getElementById('contacta').scrollIntoView({behavior: 'smooth'});
    };

    const imgCarousel = props.imatges.map((img, index) => {
        const itemClass = index === 0 ? "item active" : "item";
        return (
            <div key={`${props.customkey}_${index}`} className={itemClass}>
                <img className="d-block w-100" src={img} alt={`${props.imatgesAlt} ${index}`} title={`${props.imatgesAlt} ${index}`} />
            </div>
        )
    });

    const getRowDades = () => {
        let tecnicNotEmpty = props.dadesTecniques.tecnic && props.dadesTecniques.tecnic.length > 0;
        let colTecnicWidth = tecnicNotEmpty ? 12 / props.dadesTecniques.tecnic.length : 1;
        colTecnicWidth = colTecnicWidth < 3 ? 3 : colTecnicWidth;

        return (
            tecnicNotEmpty &&
            <div className={"row tech"}>
                {props.dadesTecniques.tecnic.map((str, index) => {
                    let key = `${props.customkey}_tecnic_${index}`;
                    return (
                        <div className={`col-sm-${colTecnicWidth}`} key={key}>
                            <h3>{str}</h3>
                        </div>
                    )
                })}
            </div>
        );
    }

    const getTaulaDies = () => {
        let etapesNotEmpty = props.dadesTecniques.etapes && props.dadesTecniques.etapes.length > 0;
        let colEtapesWidth = etapesNotEmpty ? 12 / props.dadesTecniques.etapes.length : 1;
        colEtapesWidth = colEtapesWidth < 3 ? 3 : colEtapesWidth;

        return (
            etapesNotEmpty &&
            <div className={"row stages"}>
                {props.dadesTecniques.etapes.map((etapa, index) => {
                    let key = `${props.customkey}_etapa_${index}`;
                    let detallNotEmpty = etapa.detall && etapa.detall.length > 0;
                    return (
                        <div className={`col-sm-${colEtapesWidth} colStage`} key={key}>
                            <div className={"row stage"}>
                                <h4>{etapa.etapa}</h4>
                                <p>{etapa.resum}</p>
                            </div>
                            {detallNotEmpty &&
                                <div className={"row detalls"}>
                                    {etapa.detall.map((str, index2) => {
                                        let key1 = `${key}_detall_${index2}`;
                                        return (
                                            <div className={"row detall"} key={key1}>
                                                <p>{str}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            {!detallNotEmpty &&
                                <div className={"row detalls"} style={{minHeight: '40px'}}>
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        );
    }

    // const getTaulaDies = (() => {
    //     let etapesNotEmpty = props.dadesTecniques.etapes && props.dadesTecniques.etapes.length > 0;
    //     let colEtapesWidth = etapesNotEmpty ? 12 / props.dadesTecniques.etapes.length : 1;
    //     colEtapesWidth = colEtapesWidth < 3 ? 3 : colEtapesWidth;
    //
    //     return (
    //         etapesNotEmpty &&
    //         <div>
    //             <div className={"row stage"}>
    //                 {props.dadesTecniques.etapes.map((etapa) => {
    //                     let key = `etapa_${props.dadesTecniques.etapes.indexOf(etapa)}`;
    //                     return (
    //                         <div className={`col-sm-${colEtapesWidth}`} key={key}>
    //                             <h4>{etapa.etapa}</h4>
    //                             <p>{etapa.resum}</p>
    //                         </div>
    //                     )
    //                 })}
    //             </div>
    //             <div className={"row stages"}>
    //                 {props.dadesTecniques.etapes.map((etapa) => {
    //                     let key = `etapaDetall_${props.dadesTecniques.etapes.indexOf(etapa)}`;
    //                     let detallNotEmpty = etapa.detall && etapa.detall.length > 0;
    //                     return (
    //                         <div className={`col-sm-${colEtapesWidth}`} key={key}>
    //                             {detallNotEmpty && etapa.detall.map((str) => {
    //                                 let key1 = `detall_${etapa.detall.indexOf(str)}`;
    //                                 return (
    //                                     <div className={"row"} key={key1}>
    //                                         <p>{str}</p>
    //                                     </div>
    //                                 )
    //                             })}
    //                         </div>
    //                     )
    //                 })}
    //             </div>
    //         </div>
    //     );
    // })

    const taulaTecnica = () => {
        return (
            props.dadesTecniques &&
            <div className="chart">
                {getRowDades()}
                {getTaulaDies()}
            </div>
        );
    }

    return (
        <div id="custom-card">
            <div className="container">
                <div className="card">
                    <div id={props.id} className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            {imgCarousel}
                        </div>
                        <a className="carousel-control-prev" href={"#" + props.id} role="button" data-slide="prev">
                            <span className="fa fa-chevron-circle-left" aria-hidden="true"></span>
                        </a>
                        <a className="carousel-control-next" href={"#" + props.id} role="button" data-slide="next">
                            <span className="fa fa-chevron-circle-right" aria-hidden="true"></span>
                        </a>
                    </div>
                    <div className="text-block">
                        <div style={{padding: "30px"}}>
                            <h3 style={{textAlign: "left"}}>{props.textEslogan}</h3>
                            <h2 style={{textAlign: "center"}}>{props.textTitolPrincipal}</h2>
                            <p className={"contingut"} style={{textAlign: "right"}}>{props.textContingut}</p>
                        </div>
                        <div className="btn-custom-div">
                            {/*href={props.href}*/}
                            <button onClick={handleClick}
                                    className="btn btn-custom btn-lg page-scroll">{props.textBoto}</button>
                        </div>
                    </div>
                    {taulaTecnica()}
                </div>
            </div>
        </div>
    )
}