import React from 'react';
import "./portfolio.css";
import {useTranslation} from "react-i18next";
import {SectionTitle} from "../section-title/section-title";
import fotoMarc from "../../img/Team/marc2.png";

export const Portfolio = (props) => {
    const {t} = useTranslation();

    return (
        <div id="qui-som">
            <SectionTitle title={t("about.about")} subtitle={t("about.about2")}/>
            <div className={"container"}> {/*Container*/}
                <div className={"content"}> {/*Content*/}
                    <p className={"txtProjecte"}>{t("about.projecte")}</p>
                    <div style={{
                        alignSelf: "center",
                        justifySelf: "center",
                        marginTop: "25px",
                        width:"100%",
                        borderBottom: "1px solid",
                        borderImage: "linear-gradient(to right,#071b19 0%, #12403C 25%, #cccccc 50%, #12403C 75%, #071b19 100%) 0 0 1 0"
                    }}>
                    </div>
                    <div className={"persones"}>
                        <div className={"portrait"}>
                            <img src={fotoMarc} alt={t("about.imatgeMarcAlt")} title={t("about.imatgeMarcTitle")}/>
                            <div className="caption">
                                <h3>{t("about.marc")}</h3>
                                <p>{t("about.feina")}</p>
                            </div>
                        </div>
                        <div>
                            <p className={"cita"}><cite>"{t("about.marcCita")}"</cite></p>
                            <p className={"descripcio"}>{t("about.marcTxt")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}