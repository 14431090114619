import React from 'react';
import "./apeu.css";
import {useTranslation} from "react-i18next";
import {SectionTitle} from "../section-title/section-title";
import {Freetour} from "./freetour/freetour";
import {Privades} from "./privades/privades";

export const Apeu = (props) => {
    const {t} = useTranslation();

    return (
        <div id="a-peu">
            <SectionTitle title={t("apeu")}/>
            <Freetour customkey={"freetour"}/>
            <Privades customkey={"privades"}/>
        </div>
    )
}