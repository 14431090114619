import React from "react";
import "./section-title.css"

export const SectionTitle = (props) => {
    return (
        <div className="col-md-10 col-md-offset-1 section-title text-align-center">
            <h2>{props.title}</h2>
            <p>{props.subtitle ? props.subtitle : ""}</p>
        </div>
    )
}