import React from "react";
import {Card} from "../../card/card";
import "./freetour.css";

import {useTranslation} from "react-i18next";
import {SectionSubtitle} from "../../section-title/section-subtitle";

import lleidaIndivilmandoni from "../../../img/apeu/lleida/indivilmandoni.jpg";
import lleidaPaeria from "../../../img/apeu/lleida/paeria.jpg";
import dadesTecniquesFT_ca from "../../../data/freetour/ca/DadesTecniques.json"
import dadesTecniquesFT_es from "../../../data/freetour/es/DadesTecniques.json"
import dadesTecniquesFT_en from "../../../data/freetour/en/DadesTecniques.json"

const dadesTecniquesFT = navigator.language.includes("en") ? dadesTecniquesFT_en : navigator.language.includes("es") ? dadesTecniquesFT_es : dadesTecniquesFT_ca;

export const Freetour = (props) => {
    const {t} = useTranslation();

    return (
        <div id="a-peu-free-tour">
            <SectionSubtitle title={t("freetour")}/>
            <Card id={"carouselImatgesTourLleida"}
                  href={"#contacta"}
                  imatges={[lleidaIndivilmandoni, lleidaPaeria]}
                  imatgesAlt={t("lleida.imatgesAlt")}
                  textEslogan={t("lleida.eslogan1")}
                  textTitolPrincipal={t("lleida.eslogan2")} textContingut={t("lleida.contingut")}
                  textBoto={t("lleida.esloganBoto")}
                  dadesTecniques={dadesTecniquesFT}
                  subject={t("lleida.form.subject")}
                  message={t("lleida.form.message")}
                  showOneData={true}
                  showTwoData={false}
                  showLanguage={true}
                  customkey={`${props.customkey}_free`}
            />
        </div>
    )
}