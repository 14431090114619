import React from 'react';
import "./cicloturisme.css";
import {useTranslation} from "react-i18next";
import {SectionTitle} from "../section-title/section-title";
import {Fets} from "./fets/fets";
import {Amida} from "./amida/amida";

export const Cicloturisme = (props) => {
    const {t} = useTranslation();

    return (
        <div id="cicloturisme">
            <SectionTitle title={t("cicloturisme")}/>
            <Fets customkey={"fets"}/>
            <Amida customkey={"amida"}/>
        </div>
    )
}